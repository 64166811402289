<template>
  <div class="day-rare-diseases-canvas">
    <div class="content">
      <div class="day-rare-diseases-canvas__counter" v-if="!closed">
        <span>{{ palmCount }}</span>
        {{ helpers.getNoun(palmCount, "отпечаток", "отпечатка", "отпечатков") }}
      </div>
    </div>
    <div class="day-rare-diseases-canvas__block">
      <div class="content">
        <div
          class="day-rare-diseases-canvas__diseases"
          v-if="!userPalm && !closed"
          :class="{ 'day-rare-diseases-canvas__diseases_show-tip': showTip }"
        >
          <div
            class="day-rare-diseases-canvas__disease"
            v-for="(name, ind) in diseaseNames"
            :key="ind"
            @click="setDisease(ind)"
            :class="{
              'day-rare-diseases-canvas__disease_not-active':
                currentDisease !== null && currentDisease !== ind,
            }"
          >
            <img
              :src="`https://storage.yandexcloud.net/${
                $root.backetName
              }/media/content/public/assets/img/day-of-rare-diseases/hand${
                ind + 1
              }.svg`"
            />
            <div class="day-rare-diseases-canvas__disease-name">{{ name }}</div>
            <div
              class="day-rare-diseases-canvas__disease-tip"
              v-if="currentDisease == null && ind == 0"
            >
              <svg
                class="mr-2"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12 8V12M12 16H12.01M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z"
                  stroke="#55A9DD"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              Выберите цвет ладони одной из четырех нозологий
            </div>
          </div>
        </div>
        <div
          class="day-rare-diseases-canvas__stage"
          :class="{
            'day-rare-diseases-canvas__stage_blocked': currentDisease == null,
            'day-rare-diseases-canvas__stage_disabled': userPalm,
          }"
        >
          <div class="day-rare-diseases-canvas__wrapper" v-if="!closed">
            <canvas @click="canvasClick($event)" id="canvas" />
          </div>
          <div class="day-rare-diseases-canvas__closed" v-if="closed">
            <span class="day-rare-diseases-canvas__closed-emoji">
              <img
                :srcset="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/day-of-rare-diseases/emoji.png 2x`"
              />
            </span>
            <div class="day-rare-diseases-canvas__closed-title">
              Мы собрали <br class="d-none d-md-block" />
              <span class="day-rare-diseases-canvas__closed-count">
                <span v-for="(l, i) in palmCount.toString().length" :key="i">{{
                  Array.from(palmCount.toString())[i]
                }}</span> </span
              > {{
                helpers.getNoun(
                  palmCount,
                  "отпечаток",
                  "отпечатка",
                  "отпечатков"
                )
              }}
            </div>
            <div class="day-rare-diseases-canvas__closed-description">
              Благодарим Вас за участие в акции <br />Дай «пять» редким
              пациентам
            </div>
          </div>
        </div>
        <div class="day-rare-diseases-canvas__range" v-if="!closed">
          <div class="day-rare-diseases-canvas__range-icon">
            <svg
              width="28"
              height="29"
              viewBox="0 0 28 29"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                width="28"
                height="28"
                rx="14"
                transform="matrix(-1 0 0 1 28 0.206055)"
                fill="#EFE7CF"
              />
              <path
                d="M9 14.4561L19 14.4561"
                stroke="#830051"
                stroke-linecap="square"
              />
            </svg>
          </div>
          <input
            type="range"
            min="1"
            max="5"
            step="0.1"
            value="2.8"
            v-model="zoom"
            :style="`background: linear-gradient(to right, #68BD49 ${
              ((zoom - 1) / (5 - 1)) * 100
            }%, #EFE7CF ${((zoom - 1) / (5 - 1)) * 100}%)`"
          />
          <div class="day-rare-diseases-canvas__range-icon">
            <svg
              width="28"
              height="29"
              viewBox="0 0 28 29"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                width="28"
                height="28"
                rx="14"
                transform="matrix(-1 0 0 1 28 0.206055)"
                fill="#EFE7CF"
              />
              <path
                d="M9 14.4561L19 14.4561"
                stroke="#830051"
                stroke-linecap="square"
              />
              <path
                d="M14 9.45605L14 19.4561"
                stroke="#830051"
                stroke-linecap="square"
              />
            </svg>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    closed: Boolean,
    diseases: Array
  },
  data: () => ({
    currentDisease: null,
    diseaseNames: ["НФ1", "ДЛКЛ", "ГФФ", "ATTR-амилоидоз"],
    canvasW: 1216,
    canvasH: 1216 / 2,
    stage: null,
    canvas: null,
    wrapper: null,
    zoom: null,
    ctx: null,
    showTip: false,
    socket: null,
    palmsList: [],
    userPalm: null,
    palmCount: 0,
    imgs: [],
  }),
  methods: {
    async setDisease(ind) {
      this.$set(this, "currentDisease", ind);
      this.$emit("setDisease", ind);
    },
    async loadImage(url) {
      return new Promise((r) => {
        let i = new Image();
        i.onload = () => r(i);
        i.src = url;
      });
    },
    getRandomInt(max) {
      return Math.floor(Math.random() * max);
    },
    drawImage(img, ctx, x, y, angle = 0, scale = 1, w = 24, h = 24) {
      let scale_half = scale / 2;
      ctx.save();
      ctx.translate(x + w * scale_half, y + h * scale_half);
      ctx.rotate((angle * Math.PI) / 180);
      ctx.translate(-x - w * scale_half, -y - h * scale_half);
      ctx.drawImage(img, x, y, w * scale, h * scale);
      ctx.restore();
    },
    async canvasInit() {
      this.zoom = window.innerWidth < 1220 ? 2.8 : 1;
      this.stage = document.querySelector(".day-rare-diseases-canvas__stage");
      this.canvas = document.querySelector("#canvas");
      this.wrapper = document.querySelector(
        ".day-rare-diseases-canvas__wrapper"
      );
      const stageRect = this.stage.getBoundingClientRect();
      this.canvas.width = this.canvasW;
      this.canvas.height = this.canvasH;
      this.canvas.style.transform = `scale(${
        (stageRect.height / this.canvasH) * this.zoom
      }) `;
      this.wrapper.style.width = `${
        this.canvasW * ((stageRect.height / this.canvasH) * this.zoom)
      }px`;
      this.wrapper.style.height = `${
        this.canvasH * ((stageRect.height / this.canvasH) * this.zoom)
      }px`;
      this.stage.scrollTo(
        (this.stage.scrollWidth - stageRect.width) / 2,
        (this.stage.scrollHeight - stageRect.height) / 2
      );
      this.ctx = this.canvas.getContext("2d");
    },
    async canvasClick(e) {
      if (!this.userPalm) {
        if (this.currentDisease !== null) {
          const img = await this.imgs[this.currentDisease];
          const rect = e.target.getBoundingClientRect();
          const x = e.clientX - rect.left; //x position within the element.
          const y = e.clientY - rect.top; //y position within the element.
          const xPercent = parseFloat(x / rect.width).toFixed(2);
          const yPercent = parseFloat(y / rect.height).toFixed(2);
          const angle = this.getRandomInt(180);
          this.drawImage(
            img,
            this.ctx,
            xPercent * this.canvasW,
            yPercent * this.canvasH,
            angle
          );
          if (this.socket) {
            this.socket.send(
              JSON.stringify({
                x: e.offsetX,
                y: e.offsetY,
                angle: angle,
                nosology: this.currentDisease,
              })
            );
            this.$set(this, "palmCount", this.palmCount + 1);
            this.$set(this, "userPalm", {
              x: e.offsetX,
              y: e.offsetY,
              angle: angle,
              nosology: this.currentDisease,
            });
          }

          this.$emit("registered", {
            x: e.offsetX,
            y: e.offsetY,
            angle: angle,
            nosology: this.currentDisease,
          });
        } else {
          this.$el
            .querySelector(".day-rare-diseases-canvas__counter")
            .scrollIntoView({ block: "center", behavior: "smooth" });
          this.showTip = true;
          setTimeout(() => {
            this.showTip = false;
          }, 2500);
        }
      }
    },
    async getData() {
      return this.$axios({
        method: "GET",
        url: "/api/palm-print/",
      })
        .then(async (response) => {
          const result = response.data;
          return result;
        })
        .catch((error) => {
          return false;
        });
    },
    createSocket() {
      let socket = new WebSocket(
        `${
          process.env.VUE_APP_WEBSOCKET_HOST || ''
        }/ws/palm-prints/?token=${localStorage.getItem("token")}`
      );
      this.$set(this, "socket", socket);
      socket.onopen = (e) => {
        this.socketOnOpen(e);
      };

      socket.onmessage = (e) => {
        this.socketOnMessage(e);
      };

      socket.onclose = (e) => {
        this.socketOnClose(e);
      };

      socket.onerror = (e) => {
        this.socketOnError(e);
      };
    },
    socketOnOpen(e) {
      // console.log(e);
    },
    socketOnMessage(e) {
      const m = JSON.parse(e.data);
      if (m.x) {
        let img = this.imgs[parseInt(m.nosology)];
        this.drawImage(
          img,
          this.ctx,
          parseInt(m.x),
          parseInt(m.y),
          parseInt(m.angle || 0)
        );
        this.$set(this, "palmCount", this.palmCount + 1);
      }
      if (m.close) {
        this.$emit("closePage");
      }
    },
    socketOnClose(e) {
      // console.log(e);
    },
    socketOnError(e) {
      // console.log(e);
    },
    closePage() {
      this.socket.send(JSON.stringify({ close: true }));
    },
  },
  async mounted() {
    this.imgs = [
      await this.loadImage(
        `https://storage.yandexcloud.net/${this.$root.backetName}/media/content/public/assets/img/day-of-rare-diseases/hand1.svg`
      ),
      await this.loadImage(
        `https://storage.yandexcloud.net/${this.$root.backetName}/media/content/public/assets/img/day-of-rare-diseases/hand2.svg`
      ),
      await this.loadImage(
        `https://storage.yandexcloud.net/${this.$root.backetName}/media/content/public/assets/img/day-of-rare-diseases/hand3.svg`
      ),
      await this.loadImage(
        `https://storage.yandexcloud.net/${this.$root.backetName}/media/content/public/assets/img/day-of-rare-diseases/hand4.svg`
      ),
    ];
    this.getData().then(async (res) => {
      this.$set(this, "palmsList", res.palm_prints);
      this.$set(this, "userPalm", res.user_palm_print);
      this.$set(this, "palmCount", res.palm_print_count);
      if (res.user_palm_print) {
        this.setDisease(res.user_palm_print.nosology);
      }

      this.createSocket();
      this.$emit("onFetchData", res);
      this.$nextTick(async () => {
        this.canvasInit().then(async () => {
          if (res.user_palm_print) {
            this.drawImage(
              await this.imgs[parseInt(res.user_palm_print.nosology)],
              this.ctx,
              parseInt(res.user_palm_print.x),
              parseInt(res.user_palm_print.y),
              parseInt(res.user_palm_print.angle)
            );
          }
          for (let i of res.palm_prints) {
            let img = await this.imgs[parseInt(i.nosology)];
            this.drawImage(
              img,
              this.ctx,
              parseInt(i.x),
              parseInt(i.y),
              parseInt(i.angle)
            );
          }
        });
      });
    });

    window.onresize = () => {
      this.zoom = window.innerWidth < 1220 ? 2.8 : 1;
      this.canvas.style.transform = `scale(${
        (this.stage.getBoundingClientRect().height / this.canvasH) * this.zoom
      }) `;
      this.wrapper.style.width = `${
        this.canvasW *
        ((this.stage.getBoundingClientRect().height / this.canvasH) * this.zoom)
      }px`;
      this.wrapper.style.height = `${
        this.canvasH *
        ((this.stage.getBoundingClientRect().height / this.canvasH) * this.zoom)
      }px`;
      this.stage.scrollTo(
        (this.stage.scrollWidth - this.stage.getBoundingClientRect().width) / 2,
        (this.stage.scrollHeight - this.stage.getBoundingClientRect().height) /
          2
      );
    };
  },
  watch: {
    zoom() {
      this.canvas.style.transform = `scale(${
        (this.stage.getBoundingClientRect().height / this.canvasH) * this.zoom
      }) `;
      this.wrapper.style.width = `${
        this.canvasW *
        ((this.stage.getBoundingClientRect().height / this.canvasH) * this.zoom)
      }px`;
      this.wrapper.style.height = `${
        this.canvasH *
        ((this.stage.getBoundingClientRect().height / this.canvasH) * this.zoom)
      }px`;
      this.stage.scrollTo(
        (this.stage.scrollWidth - this.stage.getBoundingClientRect().width) / 2,
        (this.stage.scrollHeight - this.stage.getBoundingClientRect().height) /
          2
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.day-rare-diseases-canvas {
  margin: 80px 0 0;
  @media screen and (max-width: 767px) {
    margin-top: 40px;
  }

  &__counter {
    margin-bottom: 16px;
    font-family: "Roboto Slab", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 100%;
    color: #626262;
    text-align: left;

    & span {
      font-family: "Roboto Slab", sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 32px;
      line-height: 100%;
      color: #59b039;
    }
  }

  &__block {
    padding: 48px 0;
    background-color: #fff9e8;
  }

  &__diseases {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    column-gap: 16px;
    @media screen and (max-width: 1220px) {
      grid-template-columns: repeat(2, 1fr);
      row-gap: 12px;
    }

    &:hover {
      & .day-rare-diseases-canvas__disease-tip {
        visibility: visible;
        opacity: 1;
        transition: opacity 0.1s linear;
      }
    }

    &_show-tip {
      & .day-rare-diseases-canvas__disease-tip {
        visibility: visible;
        opacity: 1;
        transition: opacity 0.1s linear;
      }
    }
  }

  &__disease {
    padding: 0 36px;
    position: relative;
    border-radius: 30px;
    border: 2px solid transparent;
    height: 150px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    transition: 0.3s;
    &:hover {
      @media screen and (min-width: 1220px) {
        transform: scale(1.02);
      }
    }

    @media screen and (max-width: 767px) {
      padding: 0 16px;
      overflow: hidden;
    }

    & img {
      position: absolute;
      top: 0;
      right: 16px;
      height: 100%;
      object-fit: contain;
      z-index: 2;
      filter: brightness(1.12);
    }

    &-name {
      position: relative;
      z-index: 2;
      font-family: "Roboto Slab", sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 100%;
      color: #ffffff;
      @media screen and (max-width: 767px) {
        text-align: center;
        width: 100%;
      }
    }

    &:nth-child(1) {
      border-color: #499bb7;
      background-color: #6ba4b8;
    }
    &:nth-child(2) {
      border-color: #f0b424;
      background-color: #ffc845;
    }
    &:nth-child(3) {
      border-color: #59b039;
      background-color: #68bd49;
    }
    &:nth-child(4) {
      border-color: #0a196e;
      background-color: #4957bd;
    }

    &_not-active {
      opacity: 0.5;
    }

    &-tip {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      width: 100%;
      position: absolute;
      bottom: calc(100% + 20px);
      left: 50%;
      transform: translateX(-50%);
      padding: 16px;
      box-shadow: 0px 4px 2px 0px rgba(0, 0, 0, 0.1);
      background-color: #f8faff;
      border-radius: 20px;
      font-family: "Roboto", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      color: #4f4f4f;
      opacity: 0;
      visibility: hidden;
      transition: visibility 0s 0.1s, opacity 0.1s linear;

      & > svg {
        flex-shrink: 0;
      }

      &::before {
        position: absolute;
        bottom: -8px;
        left: 50%;
        border-radius: 4px;
        transform: translateX(-50%) rotate(-45deg);
        z-index: 2;
        width: 16px;
        height: 16px;
        background-color: inherit;
        box-shadow: -2px 2px 1px 0px rgba(0, 0, 0, 0.1);
        content: "";
        display: block;
      }
    }
  }

  canvas {
    position: absolute;
    top: 0;
    left: 0;
    transform-origin: 0 0;
    object-fit: contain;
    object-position: center;
  }

  &__stage {
    margin-top: 10px;
    background-color: #fff;
    position: relative;
    aspect-ratio: 2 / 1;
    overflow: auto;
    cursor: url("https://storage.yandexcloud.net/" + $VUE_APP_BACKET_NAME + "/media/content/public/assets/img/day-of-rare-diseases/hand.ico"),
      pointer;
    border: 2px solid #efe7cf;
    -ms-overflow-style: none;
    scrollbar-width: none;
    border-radius: 30px;

    &_blocked {
      cursor: not-allowed;
    }

    &_disabled {
      cursor: default;
    }

    &::-webkit-scrollbar {
      display: none;
    }

    @media screen and (max-width: 1220px) {
      height: auto;
      width: 100%;
    }
  }

  &__range {
    display: none;
    @media screen and (max-width: 1220px) {
      margin-top: 32px;
      display: flex;
      justify-content: center;
      align-items: center;

      & input {
        margin: 0 16px;
        -webkit-appearance: none;
        appearance: none;
        width: 100%;
        height: 6px;
        cursor: pointer;
        outline: none;
        border-radius: 15px;
        background: #efe7cf;

        &::-webkit-slider-thumb {
          -webkit-appearance: none;
          appearance: none;
          height: 15px;
          width: 15px;
          background-color: #68bd49;
          border-radius: 50%;
          border: none;
          transition: background-color 0.2s ease-in-out;
        }

        &::-moz-range-thumb {
          height: 15px;
          width: 15px;
          background-color: #68bd49;
          border-radius: 50%;
          border: none;
          transition: background-color 0.2s ease-in-out;
        }

        &::-webkit-slider-thumb:hover {
          box-shadow: 0 0 0 10px rgba(104, 189, 73, 0.102);
        }
        &:active::-webkit-slider-thumb {
          box-shadow: 0 0 0 13px rgba(104, 189, 73, 0.2);
        }
        // &:focus::-webkit-slider-thumb {
        //   box-shadow: 0 0 0 13px rgba(255, 85, 0, 0.2);
        // }

        &::-moz-range-thumb:hover {
          box-shadow: 0 0 0 10px rgba(104, 189, 73, 0.102);
        }
        &:active::-moz-range-thumb {
          box-shadow: 0 0 0 13px rgba(104, 189, 73, 0.2);
        }
        // &:focus::-moz-range-thumb {
        //   box-shadow: 0 0 0 13px rgba(255, 85, 0, 0.2);
        // }
      }
    }
  }

  &__closed {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100%;

    &-emoji {
      margin-bottom: 10px;
      text-align: center;
      @media screen and (max-width: 767px) {
        width: 50px;

        & img {
          width: 100%;
        }
      }
    }

    &-title {
      font-family: "Roboto Slab", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 80px;
      line-height: 80px;
      text-align: center;
      color: #1f1f1f;
      @media screen and (max-width: 1220px) {
        font-size: 40px;
        line-height: 40px;
      }
      @media screen and (max-width: 767px) {
        font-size: 18px;
        line-height: 18px;
      }
    }

    &-count {
      @media screen and (max-width: 767px) {
        font-size: 34px;
        line-height: 34px;
        vertical-align: bottom;
      }
      & span {
        &:nth-child(4n)
         {
          color: #391a4f;
        }
        &:nth-child(4n - 1)
        {
          color: #68bd49;
        }
        &:nth-child(4n - 2)
         {
          color: #f0b424;
        }
        &:nth-child(4n - 3)
       {
          color: #6ba4b8;
        }
      }
    }

    &-description {
      margin-top: 24px;
      font-family: "Roboto Slab", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 24px;
      line-height: 32px;

      text-align: center;

      color: #0c0c0d;

      @media screen and (max-width: 1220px) {
        font-size: 18px;
        line-height: 24px;
      }

      @media screen and (max-width: 767px) {
        font-size: 16px;
        color: #757575;
      }
    }
  }
}
</style>

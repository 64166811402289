<template>
  <div class="day-rare-diseases" v-show="fetchData">
    <div class="day-rare-diseases__banner">
      <div class="content">
        <div class="day-rare-diseases__banner-text">
          <h1 class="day-rare-diseases__banner-title">
            Дай «пять» редким пациентам
          </h1>
          <p class="day-rare-diseases__banner-description">
            28 февраля - Международный День редких заболеваний
          </p>
        </div>
      </div>
      <picture>
        <source
          :srcset="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/day-of-rare-diseases/banner_mob.jpg`"
          media="(max-width: 767px)"
        />
        <source
          :srcset="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/day-of-rare-diseases/banner.jpg`"
          media="(max-width: 1219px)"
        />
        <source
          :srcset="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/day-of-rare-diseases/banner.jpg`"
          media="(min-width: 1220px)"
        />
        <img
          :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/day-of-rare-diseases/banner.jpg`"
        />
      </picture>
    </div>
    <Breadcrumbs :pages="[{ name: 'Дай «пять» редким пациентам' }]" />
    <div class="day-rare-diseases__about" v-if="!userPalm && !closed">
      <div class="content">
        <div class="day-rare-diseases__about-text">
          <h2 class="day-rare-diseases__about-title">
            Международный День Редких Заболеваний
          </h2>
          <p class="day-rare-diseases__about-description">
            В этот день Вы можете оставить свой след, чтобы выразить поддержку
            людям с редкими диагнозами: гипофосфатазия (ГФФ), дефицит лизосомной
            кислой липазы (ДЛКЛ), нейрофиброматоз 1-го типа (НФ1),
            транстиретиновый амилоидоз (ATTR-амилоидоз).
          </p>
        </div>
      </div>
    </div>
    <div class="day-rare-diseases__steps" v-if="!userPalm && !closed">
      <div class="content">
        <div class="day-rare-diseases__steps-subtitle">
          Присоединяйтесь к акции
        </div>
        <h2 class="day-rare-diseases__steps-title">
          «Дай «пять» редким пациентам»
        </h2>
        <div class="day-rare-diseases__steps-row">
          <div class="day-rare-diseases__steps-item">
            <div class="day-rare-diseases__steps-item-head">
              <div class="day-rare-diseases__steps-item-icon">
                <img
                  :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/day-of-rare-diseases/step-icon1.svg`"
                />
              </div>
              <div class="day-rare-diseases__steps-item-name">Шаг №1</div>
            </div>
            <div class="day-rare-diseases__steps-item-description">
              Выберите цвет ладони одной из четырех нозологий:
            </div>
            <div class="day-rare-diseases__steps-item-title">
              ГФФ, ДЛКЛ, НФ1, ATTR-амилоидоз
            </div>
          </div>
          <div class="day-rare-diseases__steps-item">
            <div class="day-rare-diseases__steps-item-head">
              <div class="day-rare-diseases__steps-item-icon">
                <img
                  :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/day-of-rare-diseases/step-icon2.svg`"
                />
              </div>
              <div class="day-rare-diseases__steps-item-name">Шаг №2</div>
            </div>
            <div class="day-rare-diseases__steps-item-description"></div>
            <div class="day-rare-diseases__steps-item-title">
              Кликните в свободное место на поле
            </div>
          </div>
          <div class="day-rare-diseases__steps-item">
            <div class="day-rare-diseases__steps-item-head">
              <div class="day-rare-diseases__steps-item-icon">
                <img
                  :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/day-of-rare-diseases/step-icon3.svg`"
                />
              </div>
              <div class="day-rare-diseases__steps-item-name">Шаг №3</div>
            </div>
            <div class="day-rare-diseases__steps-item-description"></div>
            <div class="day-rare-diseases__steps-item-title">
              Отпечаток закрепится на доске
            </div>
          </div>
          <div class="day-rare-diseases__steps-item">
            <div class="day-rare-diseases__steps-item-head">
              <div class="day-rare-diseases__steps-item-icon">
                <img
                  :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/day-of-rare-diseases/step-icon4.svg`"
                />
              </div>
              <div class="day-rare-diseases__steps-item-name">Шаг №4</div>
            </div>
            <div class="day-rare-diseases__steps-item-description"></div>
            <div class="day-rare-diseases__steps-item-title">
              Следите за результатами 28 февраля
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="day-rare-diseases__registered" v-if="userPalm && !closed">
      <div class="content">
        <div class="day-rare-diseases__registered-block">
          <img
            class="day-rare-diseases__registered-img"
            v-if="currentDisease !== null"
            :src="`https://storage.yandexcloud.net/${
              $root.backetName
            }/media/content/public/assets/img/day-of-rare-diseases/hand${
              currentDisease + 1
            }.svg`"
          />
          <div class="day-rare-diseases__registered-title">
            Благодарим Вас за участие в акции, посвященной Международному Дню
            редких заболеваний!
          </div>
          <div class="day-rare-diseases__registered-description">
            Вместе меняем жизнь пациентов с редкими заболеваниями к лучшему!
          </div>
          <a
            :href="diseases[currentDisease].link"
            :style="`background-color: ${diseases[currentDisease].color}`"
            class="day-rare-diseases__registered-button button"
            target="_blank"
          >
            {{ diseases[currentDisease].text }}
          </a>
          <div class="day-rare-diseases__registered-diseases-title">
            Посмотрите материалы по другим нозологиям
          </div>
          <div class="day-rare-diseases__registered-diseases">
            <a
              :href="disease.link"
              class="day-rare-diseases__registered-disease"
              :class="[`day-rare-diseases__registered-disease-${ind}`]"
              v-for="(disease, ind) in diseases"
              v-show="ind !== currentDisease"
              :key="ind"
              target="_blank"
            >
              <img
                :src="`https://storage.yandexcloud.net/${
                  $root.backetName
                }/media/content/public/assets/img/day-of-rare-diseases/hand${
                  ind + 1
                }.svg`"
              />
              <div class="day-rare-diseases__registered-disease-name">
                {{ disease.linkText }}
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>

    <div class="day-rare-diseases__closed" v-if="closed">
      <div class="content">
        <img
          @mousemove="handleHover($event)"
          @mouseleave="resetStyles($event)"
          :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/day-of-rare-diseases/title.svg`"
        />
      </div>
    </div>

    <CanvasField
      @onFetchData="onFetchData"
      @setDisease="setDisease"
      @registered="onRegistered" 
      @closePage="closePage"
      :closed="closed"
    />
    <PopUpDefault @close="modalToggle(false)" v-if="modalState">
      <img
        class="modal__img"
        v-if="currentDisease !== null"
        :src="`https://storage.yandexcloud.net/${
          $root.backetName
        }/media/content/public/assets/img/day-of-rare-diseases/hand${
          currentDisease + 1
        }.svg`"
      />
      <div class="modal__title">
        Благодарим Вас за участие в акции, посвященной Международному Дню редких
        заболеваний!
      </div>
      <div class="modal__description">
        Вместе меняем жизнь пациентов с редкими заболеваниями к лучшему!
      </div>
      <a
        :href="diseases[currentDisease].link"
        target="_blank"
        :style="`background-color: ${diseases[currentDisease].color}`"
        class="modal__button button"
      >
        {{ diseases[currentDisease].text }}
      </a>
    </PopUpDefault>
  </div>
</template>
<script>
import Breadcrumbs from "@/components/pageComponents/Breadcrumbs.vue";
import CanvasField from "@/components/dayOfRareDiseases/CanvasField.vue";
import PopUpDefault from "@/components/pageComponents/PopUpDefault.vue";
export default {
  name: "DayOfRareDiseases",
  components: {
    Breadcrumbs,
    CanvasField,
    PopUpDefault,
  },
  data: () => ({
    fetchData: false,
    modalState: false,
    currentDisease: 3,
    closed: false,
    userPalm: false,
    diseases: [
      {
        text: "Подробнее о НФ1",
        linkText: "НФ1",
        link: "https://az-most.ru/therapeutic-areas/rare-diseases/neirofibromatoz?utm_source=high-five&utm_medium=az-most&utm_campaign=nf1",
        color: "#2186AF",
      },
      {
        text: "Подробнее о ДЛКЛ",
        linkText: "ДЛКЛ",
        link: "https://az-most.ru/therapeutic-areas/rare-diseases/dlkl?utm_source=high-five&utm_medium=az-most&utm_campaign=dlkl",
        color: "#F0B424",
      },
      {
        text: "Подробнее о ГФФ",
        linkText: "ГФФ",
        link: "https://az-most.ru/therapeutic-areas/rare-diseases/gipofosfataziya-gff?utm_source=high-five&utm_medium=az-most&utm_campaign=gff",
        color: "#59B039",
      },
      {
        text: "Подробнее о ATTR-амилоидозе",
        linkText: "ATTR-амилоидоз",
        link: "https://amyloidosis.az-most.ru/login?utm_source=high-five&utm_medium=az-most&utm_campaign=attr",
        color: "#5865D2",
      },
    ],
  }),
  methods: {
    modalToggle(state) {
      this.$set(this, "modalState", state);
    },
    async setDisease(ind) {
      this.$set(this, "currentDisease", ind);
    },
    onFetchData(result) {
      this.fetchData = true;
      this.$set(this, "closed", result.closed);
      this.$set(this, "userPalm", result.user_palm_print);
    },
    onRegistered(user_palm_print) {
      this.modalToggle(true);
      this.$set(this, "userPalm", user_palm_print);
      window.scrollTo(0,0)
    },
    closePage() {
      this.$set(this, "closed", true);
      window.scrollTo(0, 0);
    },
    handleHover(e) {
      const { offsetWidth: width, offsetHeight: height } = e.target;
      const { offsetX: x, offsetY: y } = e;
      const rotateX = (y / height - 0.5) * -20;
      const rotateY = (x / width - 0.5) * 20;
      e.target.style.transform = `rotateX(${rotateX}deg) rotateY(${rotateY}deg) scale(1.05)`;
    },
    resetStyles(e) {
      e.target.style.transition = "transform 0.5s ease-out";
      e.target.style.transform = `perspective(${e.currentTarget.clientWidth}px) rotateX(0deg) rotateY(0deg)`;
    },
    onRegistered(user_palm_print) {
      this.modalToggle(true)
      this.$set(this, "userPalm", user_palm_print);
    }
  },
  mounted() {},
};
</script>
<style lang="scss" scoped>
.day-rare-diseases {
  &__banner {
    position: relative;
    & img {
      width: 100%;
      height: auto;
      object-fit: contain;
      object-position: top center;
    }
    & .content {
      height: 100%;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      display: flex;
      justify-content: flex-start;
      align-items: center;
      @media screen and (max-width: 1220px) {
      }
      @media screen and (max-width: 767px) {
        height: auto;
        top: auto;
        transform: none;
        left: 0;
        bottom: 44px;
      }
    }
    &-text {
      max-width: 50%;
      @media screen and (max-width: 1220px) {
        max-width: 60%;
      }
      @media screen and (max-width: 767px) {
        max-width: none;
      }
    }
    &-title {
      font-family: "Roboto Slab", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 42px;
      line-height: 48px;
      color: #ffffff;
      @media screen and (max-width: 1220px) {
        font-size: 32px;
        line-height: 38px;
      }
    }
    &-description {
      margin-top: 24px;
      padding-top: 24px;
      border-top: 1px solid #fff;
      font-family: "Roboto", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #ffffff;
      width: fit-content;
    }
  }
  &__about {
    &-text {
      max-width: 900px;
      margin: 0 auto;
    }
    &-title {
      margin-top: 40px;
      margin-bottom: 40px;
      font-family: "Roboto Slab", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 32px;
      line-height: 29px;
      text-align: center;
      text-transform: uppercase;
      color: #c02b6e;
      @media screen and (max-width: 767px) {
        font-size: 32px;
        line-height: 40px;
      }
    }
    &-description {
      font-family: "Roboto", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 140%;
      text-align: center;
      color: #3c4242;
    }
  }
  &__steps {
    margin: 80px 0;
    @media screen and (max-width: 767px) {
      margin: 32px 0;
    }
    &-subtitle {
      margin-bottom: 24px;
      font-family: "Roboto Slab", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 24px;
      line-height: 56px;
      text-align: center;
      color: #391a4f;
      @media screen and (max-width: 767px) {
        line-height: 32px;
      }
    }
    &-title {
      font-family: "Roboto Slab", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 40px;
      line-height: 56px;
      text-align: center;
      color: #68bd49;
      @media screen and (max-width: 767px) {
        font-size: 32px;
        line-height: 40px;
      }
    }
    &-row {
      margin-top: 40px;
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      column-gap: 16px;
      @media screen and (max-width: 1220px) {
        grid-template-columns: 1fr;
        row-gap: 16px;
      }
    }
    &-item {
      padding: 24px 16px;
      border: 2px solid #f4f7f3;
      background-color: #fff;
      border-radius: 20px;
      box-shadow: 0px 21px 51px -37px #59b039;
      display: flex;
      flex-direction: column;
      &-head {
        display: flex;
        justify-content: flex-start;
        align-items: center;
      }
      &-icon {
        width: 60px;
        flex-shrink: 0;
        margin-right: 16px;
      }
      &-name {
        font-family: "Roboto", sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;
        text-transform: uppercase;
        color: #4f4f4f;
      }
      &-description {
        margin: 16px 0;
        font-family: "Roboto", sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 18px;
        color: #4f4f4f;
      }
      &-title {
        margin-top: auto;
        font-family: "Roboto Slab", sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 22px;
        color: #4f4f4f;
      }
    }
  }
  &__registered {
    &-block {
      max-width: 900px;
      margin: 48px auto;
    }
    &-img {
      width: 74px;
      margin: 0 auto 16px;
    }
    &-title {
      margin-bottom: 16px;
      font-family: "Roboto Slab", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 28px;
      line-height: 32px;
      text-align: center;
      color: #0c0c0d;
    }
    &-description {
      margin-bottom: 16px;
      font-family: "Roboto", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 26px;
      text-align: center;
      color: #1f1f1f;
    }
    &-button {
      color: #fff;
      margin: 0 auto;
      width: fit-content !important;
      &:hover {
        opacity: 0.9;
      }
    }

    &-diseases {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      column-gap: 16px;
      @media screen and (max-width: 767px) {
        grid-template-columns: repeat(2, 1fr);
        row-gap: 12px;
      }

      &:hover {
        & .day-rare-diseases-canvas__disease-tip {
          visibility: visible;
          opacity: 1;
          transition: opacity 0.1s linear;
        }
      }

      &-title {
        margin-top: 80px;
        margin-bottom: 32px;
        font-family: "Roboto", sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        line-height: 26px;
        color: #1f1f1f;
        text-align: center;
      }
    }

    &-disease {
      overflow: hidden;
      padding: 0 36px;
      position: relative;
      border-radius: 30px;
      border: 2px solid transparent;
      height: 150px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      cursor: pointer;
      transition: 0.3s;
      &:hover {
        @media screen and (min-width: 1220px) {
          transform: scale(1.02);
        }
      }

      @media screen and (max-width: 767px) {
        padding: 0 16px;
      }

      & img {
        position: absolute;
        top: 0;
        right: 16px;
        height: 100%;
        object-fit: contain;
        z-index: 2;
        filter: brightness(1.12);
      }

      &-name {
        position: relative;
        z-index: 2;
        font-family: "Roboto Slab", sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 100%;
        color: #ffffff;
      }

      &:nth-child(1) {
        border-color: #499bb7;
        background-color: #6ba4b8;
        @media screen and (max-width: 767px) {
          grid-column-start: 1;
          grid-column-end: 3;
        }
      }
      &:nth-child(2) {
        border-color: #f0b424;
        background-color: #ffc845;
      }
      &:nth-child(3) {
        border-color: #59b039;
        background-color: #68bd49;
      }
      &:nth-child(4) {
        border-color: #0a196e;
        background-color: #4957bd;
      }
    }
  }
  &__closed {
    animation: fadeIn 2s ease 1s backwards;
    & img {
      max-width: 100%;
      margin: 32px auto 0;

      will-change: transform, filter;
      transition: 0.1s ease;
      transform-style: preserve-3d;
      transform-origin: center;
      @media (prefers-reduced-motion) {
        transform: none !important;
      }
      filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.1));
      &:hover {
        animation: elementGlow 2s infinite;
        filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.2))
          drop-shadow(0px 0px 10px rgba(66, 133, 244, 0.2));
      }

      @keyframes elementGlow {
        0% {
          filter: brightness(1);
        }
        50% {
          filter: brightness(1.1);
        }
        100% {
          filter: brightness(1);
        }
      }
    }
  }
  & .modal {
    &__img {
      width: 74px;
      margin: 0 auto 16px;
    }
    &__title {
      margin-bottom: 16px;
      font-family: "Roboto Slab", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 21px;
      line-height: 26px;
      text-align: center;
      color: #8a226f;
    }
    &__description {
      margin-bottom: 16px;
      font-family: "Roboto", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 26px;
      text-align: center;
      color: #1f1f1f;
    }
    &__button {
      color: #fff;
      margin: 0 auto;
    }
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
</style>